// v-tab-consume.js
// Textareas sollen grundsätzlich tab konsumieren.
// Dieses File wird in App.vue importiert und
// dort wo es gebraucht wird mit <textarea v-tab-consume></textarea> aktiviert

const vTabConsume = {
    mounted(el, binding) {
        el.addEventListener('keydown', function (e) {
            if (e.key === 'Tab') {
                e.preventDefault();
                const start = this.selectionStart;
                const end = this.selectionEnd;
                const tabCharacter = '    '; // Hier kannst du die Tab-Größe anpassen
                this.value = this.value.substring(0, start) + tabCharacter + this.value.substring(end);
                this.selectionStart = this.selectionEnd = start + tabCharacter.length;
            }
            else if (e.key === 'Enter' && e.ctrlKey) {
                e.preventDefault();
                let eventName = 'ctrl-enter';
                if (binding.value && binding.value.eventName) {
                    eventName = binding.value.eventName;
                }
                this.dispatchEvent(new CustomEvent(eventName, { bubbles: true }));
            }
            else if (e.key === 'Escape') {
                e.preventDefault();
                let escapeEventName = binding.value && binding.value.escapeEventName;
                if(escapeEventName) {
                    this.dispatchEvent(new CustomEvent(escapeEventName, {bubbles: true}));
                }
                else {
                    this.select(); // Selektiere den gesamten Text in der Textarea
                }
            }
        });
    },
};

export default vTabConsume;
