<!-- DemoForNothingView.vue -->
<template>
    <div class="component-view">
        Demo for nothing
        <hr>
        {{ textContent }}
        <hr>
    </div>
</template>

<script>
export default {
    props: ['rowData'],
  data() {
    return {
            textContent: ''
    };
  },
    created() {
        fetch('nothing.txt')
            .then(response => response.text())
            .then(text => {
                this.textContent = text;
    });
  },
};
</script>

