<template>
   <div class="group-item">
        <button @click="createNewIdea">New Idea</button>
    </div>
   <div class="group-item">
        <label for="showCompleted" class="labeled-checkbox">
            <input type="checkbox" id="showCompleted" v-model="showCompleted" @change="toggleShowCompleted">
            Show Completed
        </label>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus.js';

export default {
    name: 'IdeasListView',
    data() {
        return {
            showCompleted: false
        };
    },
    methods: {
        createNewIdea() {
            EventBus.emit('createNewIdea');
        },
        toggleShowCompleted() {
            EventBus.emit('toggleShowCompleted', this.showCompleted);
        }
    }
}
</script>
