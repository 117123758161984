import { createStore } from 'vuex';
import { EventBus } from '@/eventBus.js';
import { arrayToMap } from '@/utils/helpers';


export default createStore({
    state: {
        settings: [], //
        maxMovers: {
            requestParams: {
                s: '2021-01-01',
                w: 40,
                p: 30.0,
                b: [10, 20, 40],
            },
            selectedRow: null,
            header: [], // headerMap wird automatisch berechnet, wenn header gesetzt wird.
        },
        ideasList: {
            selectedRow: null,
            selectedIndex: null,
            header: [],
            filter: {}, // wird in IdeasSearchView verwaltet
        },
        freestyleQuery: {
            selectedRow: null,
            header: [],
        }
    },
    mutations: {
        setFeatureState(state, { feature, key, value }) {
            if (state[feature]) {
                state[feature][key] = value;

                // Im Fall von header ['A', 'B', ...] brauchen wir noch ein objekt headerMap { 'A':0, 'B':1, ...}
                if (key === 'header' && Array.isArray(value)) {
                    state[feature].headerMap = arrayToMap(value);
                }
            }
            else {
                console.error(`Feature ${feature} existiert nicht im State.`);
            }
        },
        setSelectedIndex(state, {feature, index}) {
            if(state[feature]) {
                state[feature].selectedIndex = index;
            }
            else {
                console.error(`Feature ${feature} existiert nicht im State.`);
            }
        },
        addMaxMoversDay(state) {
            state.maxMovers.requestParams.b.push(0);
        },
        updateMaxMoversDay(state, { index, value }) {
            state.maxMovers.requestParams.b.splice(index, 1, Number(value));
        },
        removeMaxMoversDay(state, index) {
            state.maxMovers.requestParams.b.splice(index, 1);
        },
        updateRequestParams(state, { feature, key, value, type }) {
            let convertedValue;
            switch(type) {
                case 'number':
                    convertedValue = Number(value);
                    if (isNaN(convertedValue)) {
                        console.error(`Ungültiger numerischer Wert für ${key} in ${feature}: ${value}`);
                        return;
                    }
                    break;
                case 'string':
                    convertedValue = String(value);
                    break;
                default:
                    convertedValue = value;
            }

            if (state[feature] && state[feature].requestParams) {
                state[feature].requestParams[key] = convertedValue;
            }
            else {
                console.error(`Feature ${feature} oder requestParams für ${feature} existiert nicht im State.`);
            }
        },
        addDay(state) {
          state.requestParams.b.push(0);
        },
        updateDay(state, { index, value }) {
          state.requestParams.b.splice(index, 1, Number(value));
        },
        removeDay(state, index) {
          state.requestParams.b.splice(index, 1);
        },
        setSettings(state, settings) {
            state.settings = settings;
        }
    },
    actions: {
        sendQuery({state}, feature) {
            if(!state[feature]) {
                console.error(`ERROR: Es gibt kein Feature ${feature}`);
                return;
            }
            const requestParams = state[feature].requstParams;
            EventBus.emit('send-query', requestParams);
        },
        async initSettings({commit}) {
            function transformSettings(rawData) {
                const transformed = {};

                rawData.forEach(row => {
                    const keyParts = row[0].split('.'); // zerlege key - z.B. qe.ideas.categories.allgemein
                    const value = row.find((item, index) => item !== null && index !== 0); // extrahiere den value
                    const settingName = keyParts.pop(); // Letzter Teil ist der Name des Wertes
                    const path = keyParts.join('.'); // Alles davor ist der Pfad 

                    // Falls Pfad noch nicht existiert, dann anlegen
                    if (!transformed[path]) { 
                        transformed[path] = [];
                    }
                    // Einfuegen
                    transformed[path].push({ key: settingName, value: value });
                });
                return transformed;
            }
            try {
                const response = await fetch('/qe/db', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(["q", "settings", {}, ["key", "int_value", "text_value", "float_value"]])
                });

                if(!response.ok) {
                    throw new Error("Kann Settings nicht laden");
                }
                const data = await response.json();
                const settings = transformSettings(data.rows);
                commit('setSettings', settings);
            }
            catch(error) {
                console.error('Fehler bei der Anfrage: ', error);
            }
        },
    },
    getters: {
        getSetting: (state) => (key) => {
            return state.settings[key] || [];
        }
    },
});

