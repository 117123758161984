<template>
    <div class="component-view">
        <!-- Mamo Chart -->
        <div class="chart-container"
            tabindex="0"
            ref="mamoChart"
            @click="selectChart('mamo', $event)">
            <img :src="generateChartUrl('mamo')" alt="Mamo Chart">
        </div>
        <!-- MamoNext Chart -->
        <div class="chart-container"
            tabindex="0"
            ref="mamonextChart"
            @click="selectChart('mamonext', $event)">
            <img :src="generateChartUrl('mamonext')" alt="MamoNext Chart" @click="selectChart('mamonext')">
            <div class="chart-input-container">
                <span class="label">Days: </span>
                <input class="input-field"
                       v-select-all-on-focus
                       v-model="days"
                       type="number"
                       style="width: 50px;"
                       name="tage">
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus.js';

export default {
    props: ['header'],
    data() {
        return {
            days : 100,
            mamosize: "1000x500",
            mamonextsize: "400x200",
            selectedChart: null,
        };
    },
    computed: {
        rowData() {
            return this.$store.state.maxMovers.selectedRow;
        }
    },
    methods: {
        generateChartUrl(type) {
            const params = this.$store.state.maxMovers.requestParams;
            const symbol = this.rowData ? this.rowData[0] : '';

            let url = `/qe/chart?s=${encodeURIComponent(symbol)}`;
            url += `&c=${encodeURIComponent(params.s)}`;
            url += `&w=${encodeURIComponent(params.w)}`;
            url += `&p=${encodeURIComponent(params.p)}`;
            url += `&b=${encodeURIComponent(params.b.join(','))}`;
            url += `&d=${encodeURIComponent(this.days)}`;
            url += `&t=${type}`;
            let size = type+'size';
            if (this[size]) {
                url += `&i=${encodeURIComponent(this[size])}`;
            }
            url += `&_=${new Date().getTime()}`;
            return url;
        },
        selectChart(chart) {
            this.selectedChart = chart;
        },
        handleKeydown(e) {
            if(!this.selectedChart) return;
            const chartRef = this.$refs[this.selectedChart + 'Chart'];

            if(document.activeElement !== chartRef )
                return;

            let processed = false;
            if (['+', '-'].includes(e.key)) {
                this.adjustChartSize(e.key);
                processed = true;
            }
            else if (e.key === 'ArrowRight' || e.key==='ArrowDown') {
                EventBus.emit('chart-next');
                processed = true;
            } else if (e.key === 'ArrowLeft' || e.key==='ArrowUp') {
                EventBus.emit('chart-previous');
                processed = true;
            }
            if( processed )
            {
                e.preventDefault();
            }
        },
        adjustChartSize(key) {
            if (this.selectedChart) {
                let sizeKey = this.selectedChart + 'size';
                let dimensions = this[sizeKey].split('x').map(Number);
                let aspectRatio = dimensions[0] / dimensions[1];
                let step = key === '+' ? 10 : -10;

                let newWidth = dimensions[0] + step;
                let newHeight = newWidth / aspectRatio;

                this[sizeKey] = `${Math.round(newWidth)}x${Math.round(newHeight)}`;
            }
        },
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeydown);
    },
    unmounted() {
        window.removeEventListener('keydown', this.handleKeydown);
    },

};
</script>
<style>
    .chart-container {
        display: flex;
        align-items: top;
        margin-bottom: 20px;
    }
    .chart-input-container {
        margin-left: 5px;
    }
    .chart-input-container .input-field {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0.5rem;
        margin-left: 0.5rem;
    }
    .chart-input-container .label {
        font-weight: 700;
    }
</style>
