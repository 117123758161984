import { createApp, nextTick } from 'vue';
import App from './App.vue';
import store from './store';
import vTabConsume from './utils/v-tab-consume.js';
import './styles.css';

import MaximizeIcon from './components/ui/icons/MaximizeIcon.vue';
import MinimizeIcon from './components/ui/icons/MinimizeIcon.vue';
import NextIcon from './components/ui/icons/NextIcon.vue';
import PreviousIcon from './components/ui/icons/PreviousIcon.vue';
import EditIcon from './components/ui/icons/EditIcon.vue';
import AddNewIcon from './components/ui/icons/AddNewIcon.vue';
import MinusIcon from './components/ui/icons/MinusIcon.vue';
import SubmitIcon from './components/ui/icons/SubmitIcon.vue';

const app = createApp(App);
app.use(store);

app.component('maximize-icon', MaximizeIcon);
app.component('minimize-icon', MinimizeIcon);
app.component('next-icon', NextIcon);
app.component('previous-icon', PreviousIcon);
app.component('edit-icon', EditIcon);
app.component('addnew-icon', AddNewIcon);
app.component('minus-icon', MinusIcon);
app.component('submit-icon', SubmitIcon);

app.directive('tab-consume', vTabConsume);
app.directive('select-all-on-focus', {
    mounted(el) {
        el.addEventListener('focus', () => {
            el.select();
        });
    }
});

app.mixin({
    mounted() {
        nextTick(() => {
            this.disableAutocomplete();
        });
    },
    methods: {
        disableAutocomplete() {
            const elements = document.querySelectorAll('input, textarea');
            elements.forEach(el => {
                el.setAttribute('autocomplete', 'off');
            });
        }
    }
});

app.mount('#app');
