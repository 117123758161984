<template>
    <div id="app">
        <div style="margin-top:10px;">
        <!-- Wenn Hier die Hoehe geaendert wird, dann muss auch
        die Berechnung der Hoehe in .master-view und .detail-vue in styles.css angepasst werden.
        mit margin-top=10px wäre die korrekte Höhe der Beiden Views: max-height:calc(100vh - 49px); -->
        </div>
        <div class="control-row">
            <div class="group-left">
                <!-- Menü der ausgewählten Hauptkomponente -->
                <component
                  :is="selectedFeature + 'Menu'"
                  :key="selectedFeature">
                </component>
            </div> <!-- "group-left" -->

            <div class="group-right">
                <div class="group-item">
                    <!-- Kontrollelemente wie das Dropdown-Menü -->
                    <select v-model="selectedFeature" name="feature-select" class="app-feature-select">
                        <option value="MaxMovers">Max Movers</option>
                        <option value="IdeasList">Ideas</option>
                        <option value="FreestyleQuery">Freestyle Query</option>
                    </select>
                </div>
            </div> <!-- "group-right" -->
        </div> <!-- "control-row" -->

        <!-- Hauptkomponentenbereich -->
        <main>
            <component
                :is="selectedFeature"
                :key="selectedFeature">
            </component>
        </main>

        <confirmation-dialog 
            v-bind:visible="dialogVisible"
            :title="dialogTitle"
            :message="dialogMessage"
            @confirm="handleConfirm"
            @cancel="handleCancel"
        />
    </div> <!-- app -->
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';

// MaxMovers
import MaxMoversMenu from './components/maxmovers/MaxMoversMenu.vue';
import MaxMovers from './components/maxmovers/MaxMovers.vue';

// IdeasList
import IdeasListMenu from './components/ideas/IdeasListMenu.vue';
import IdeasList from './components/ideas/IdeasList.vue';

// FreestyleQuery
import FreestyleQueryMenu from './components/freestyle/FreestyleQueryMenu.vue';
import FreestyleQuery from './components/freestyle/FreestyleQuery.vue';

// EventBus
import { EventBus } from '@/eventBus.js';
import ConfirmationDialog from './components/ui/ConfirmationDialog.vue';

export default {
    components: {
        MaxMovers,
        MaxMoversMenu,
        IdeasList,
        IdeasListMenu,
        FreestyleQuery,
        FreestyleQueryMenu,
        ConfirmationDialog,
    },
    data() {
        return {
            selectedFeature: 'FreestyleQuery', // Default-Feature
            // Confirmation-Dialog-Daten
            dialogVisible: false,
            dialogTitle: '',
            dialogMessage: '',
            confirmCallback: null
        };
    },
    watch: {
        selectedFeature() {
            EventBus.emit('feature-selected', this.selectedFeature);
        }
    },
    methods: {
        showDialog(title, message, callback) {
            this.dialogTitle = title;
            this.dialogMessage = message;
            this.confirmCallback = callback || null;
            this.dialogVisible = true;
        },
        handleConfirm() {
            if (this.confirmCallback) this.confirmCallback(true);
            this.dialogVisible = false;
        },
        handleCancel() {
            if (this.confirmCallback) this.confirmCallback(false);
            this.dialogVisible = false;
        },
    },
    created() {
        EventBus.on('show-confirmation-dialog', ({ title, message, callback }) => {
            this.showDialog(title, message, callback);
        });
    },
    beforeUnmount() {
        EventBus.off('show-confirmation-dialog', this.showDialog);
    },
    setup() {
        const store = useStore();
        onMounted(() => {
            store.dispatch('initSettings');
        });
    },
};
</script>

