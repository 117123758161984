<template>
    <div class="ideas-list-view">
        <div class="form-header">
            <div class="header-item"><span>ID: {{idea.id}}</span></div>
            <div class="header-item"><span>Created: {{idea.created}}</span></div>
            <div class="header-item"><span>Updated: {{idea.updated}}</span></div>
            <div class="header-item status-dropdown">
            <label for="status-select">Status:</label>
            <select id="status-select" v-model="idea.status">
                <option v-for="option in statusOptions" :key="option.key" :value="option.key">
                {{ option.value }}
                </option>
            </select>
        </div>
    </div>
    <div class="form-body">
        <div class="form-row">
            <label for="project-input">Project:</label>
            <input id="project-input" type="text" v-model="idea.project" />
        </div>
        <div class="form-row">
            <label for="subject-input">Subject:</label>
            <input id="subject-input" type="text" v-model="idea.subject" />
            <label for="prio-input" class="prio-label">Prio:</label>
            <input id="prio-input" class="input-field" v-select-all-on-focus v-model="idea.prio" type="number" style="width: 50px;" name="Prio">
        </div>
        <div class="form-row">
            <label for="title-input">Title:</label>
            <input id="title-input" type="text" v-model="idea.title" />
        </div>
        <div class="form-row">
            <label for="description-textarea">Description:</label>
            <textarea id="description-textarea" v-tab-consume v-model="idea.description" lang="de" spellcheck="true"></textarea>
        </div>
        </div>
        <div v-if="error" class="error-message">{{ error }}</div>
        <div class="form-actions">
            <button type="button" class="delete" @click="askDeleteIdea">Delete</button>
            <button type="button" class="cancel" @click="cancelIdea">Cancel</button>
            <button type="button" class="submit" @click="submitIdea">Submit</button>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus.js';
import './styles.css';

export default {
    name: 'IdeasListView',
    data() {
        return {
            idea: {},
            originalIdea: null, // um bei "submit" zu pruefen zu können ob sich etwas geaendert hat
            error: null
        };
    },
    computed: {
        selectedRow() {
            return this.$store.state.ideasList.selectedRow;
        },
        headerMap() {
            return this.$store.state.ideasList.headerMap;
        },
        statusOptions() {
            return this.$store.getters.getSetting('qe.ideas.status');
        },
        subjectOptions() {
            return this.$store.getters.getSetting('qe.ideas.subject');
        },
    },
    methods: {
        mapRowToIdea(details, header) {
            const columnIndices = header.reduce((acc, columnName, index) => {
                acc[columnName] = index;
                return acc;
            }, {});

            return {
                id: details[columnIndices['id']],
                project: details[columnIndices['project']],
                subject: details[columnIndices['subject']],
                title: details[columnIndices['title']],
                prio: details[columnIndices['prio']],
                description: details[columnIndices['description']],
                status: details[columnIndices['status']],
                created: details[columnIndices['created']],
                updated: details[columnIndices['updated']]
            };
        },

        newIdeaForm() {
            this.idea = {
                id: null,
                project: null,
                subject: null,
                title: null,
                description: null,
                status: null,
                created: null,
                updated: null,
                prio: null
            };
            this.originalIdea = JSON.parse(JSON.stringify(this.idea));
        },
        async fillIdeaForm() {
            const id = this.selectedRow ? this.selectedRow[this.headerMap['ID']] : -1;

            // in queryies sind alle where clauses strings, weil z.B. auch nach "prio" : "<15" gesucht werden kann
            let requestPayload = [ 'q', "ideas", {"id":"="+id}, ]; 

            try {
                const response = await fetch('/qe/db', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestPayload),
                })
                if(!response.ok) {
                    throw new Error("Netzanfrage fehlgeschlagen");
                }

                const data = await response.json();

                this.types = data.types;
                if (data.rows && Array.isArray(data.rows) && data.rows.length > 0) {
                    const details = data.rows[0];
                    const header = data.header;
                    this.idea = this.mapRowToIdea(details, header);
                    this.originalIdea = JSON.parse(JSON.stringify(this.idea));
                }
                else {
                    this.error = "keine Daten";
                }
            }
            catch(error) {
                this.error = `Fehler beim Abrufen Details zur Idee: ${error.message}`;
            }
        },
        createRequestBody() {
            const ideaData = {
                project: this.idea.project,
                subject: this.idea.subject,
                title: this.idea.title,
                description: this.idea.description || '',
                status: this.idea.status,
                prio: this.idea.prio,
            };

            if (this.idea.id > 0) {
                return ["u", "ideas", this.idea.id, ideaData];
            } else {
                return ["i", "ideas", ideaData];
            }
        },

        submitIdea() {
            this.error = null;
            if (JSON.stringify(this.idea) === JSON.stringify(this.originalIdea)) {
                return; // 'Keine Änderungen erkannt, Abbruch der Submit-Aktion
            }
            const requestBody = this.createRequestBody();

            // Sende den Request an das Backend
            fetch('/qe/db', { // provoziere Fehler mit /xqe/...
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(requestBody),
            })
            .then(response => response.json())
            .then(data => {
                let id;
                if(!data.success) {
                    throw new Error('DB: ' +  data.error ? data.error : 'kein Hinweis auf Ursache');
                }
                if(data.inserted_id)
                {
                    id = data.inserted_id;
                }
                else
                {
                    id = this.idea.id;
                }
                EventBus.emit('ideaUpdated', { id: id });
            })
            .catch(data => {
                this.error = `${data}`;
            });
        },
        askDeleteIdea() {
            EventBus.emit('show-confirmation-dialog', {
                title: 'Idee Löschen',
                message: 'Soll die Idee wirklich gelöscht werden?',
                callback: confirmed => {
                    if (confirmed) {
                        this.doDeleteIdea();
                    }
                }
            });
        },
        doDeleteIdea() {
            if (this.idea.id && this.idea.id > 0) {
                const requestBody = ["d", "ideas", this.idea.id];
                fetch('/qe/db', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                })
                .then(response => response.json())
                .then((data) => {
                    if(data.message && !data.message.success) {
                        throw new Error(data.message.error ? data.message.error : 'kein Hinweis auf Ursache');
                    }
                    EventBus.emit('ideaUpdated', { type: 'd', id: this.idea.id });
                })
                .catch(error => {
                    this.error = `Fehler beim Löschen der Idee: ${error.message}`;
                });
            }
            this.cancelIdea(); // Dies ist notwendig, damit das Formular schliesst
        },
        cancelIdea() {
            EventBus.emit('newIdeaCanceled');
        },
    },
    watch: {
        selectedRow(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal) {
                    this.fillIdeaForm();
                }
                else {
                    this.newIdeaForm();
                }
            }
        }
    },
    mounted() {
        EventBus.on('createNewIdea', this.newIdeaForm);
        this.fillIdeaForm();
    },
    beforeUnmount() {
        EventBus.off('createNewIdea', this.newIdeaForm);
    }
}
</script>

