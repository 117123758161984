<!-- IdeasSearchView.vue -->
<template>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap">
    <!-- Reserve-Font-->
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap">


    <div class="ideas-list-view search-form">
        <div v-if="error" class="error-message">{{ error }}</div>
        <div class="form-row bottom-border">
            <span class="filter-label">Search-Filter</span>
            <button @click="resetFilter()" class="reset" title="Filter zurücksetzen">&times;</button>
        </div>
        <div class="form-body">
            <div class="form-row">
                <label for="id-search-input">ID:</label>
                <input id="id-search-input" ref="idInput" type="text" v-model="localFilter.id" @blur="updateFilter" />
                <button @click="createDefaultFilter('id', 'ID')" class="take">&lt;</button>
            </div>
            <div class="form-row">
                <label for="project-search-input">Project:</label>
                <input id="project-search-input" ref="projectInput" type="text" v-model="localFilter.project" @blur="updateFilter" />
                <button @click="createDefaultFilter('project', 'Project')" class="take">&lt;</button>
            </div>
            <div class="form-row">
                <label for="subject-search-input">Subject:</label>
                <input id="subject-search-input" ref="subjectInput" type="text" v-model="localFilter.subject" @blur="updateFilter" />
                <button @click="createDefaultFilter('subject', 'Subject')" class="take">&lt;</button>
            </div>
            <div class="form-row">
                <label for="title-search-search-input">Title:</label>
                <input id="title-search-search-input" ref="titleInput" type="text" v-model="localFilter.title" @blur="updateFilter" />
                <button @click="createDefaultFilter('title', 'Title')" class="take">&lt;</button>
            </div>
            <div class="form-row">
                <label for="description-search-input">Description:</label>
                <input id="description-search-input" ref="descriptionInput" type="text" v-model="localFilter.description" @blur="updateFilter" />
                <button @click="createDefaultFilter('description', 'Description')" class="take">&lt;</button>
            </div>
            <div class="form-row">
                <label for="status-search-input">Status:</label>
                <input id="status-search-input" ref="statusInput" type="text" v-model="localFilter.status" @blur="updateFilter" />
                <button @click="createDefaultFilter('status', 'Status')" class="take">&lt;</button>
            </div>
            <div class="form-row">
                <label for="prio-search-input">Prio:</label>
                <input id="prio-search-input" ref="prioInput" type="text" v-model="localFilter.prio" @blur="updateFilter" />
                <button @click="createDefaultFilter('prio', 'Prio')" class="take">&lt;</button>
            </div>
        </div>
    </div> <!-- ideas-list-view -->
</template>

<script>
import { EventBus } from '@/eventBus.js';
import {mapState, mapMutations} from 'vuex';
import './styles.css';

export default {
    name: 'IdeasSearchView',
    data() {
        return {
            localFilter : {
                id: null,
                project: null,
                subject: null,
                title: null,
                description: null,
                status: null,
                created: null,
                updated: null,
                prio: null
            },
            error: null, // wird von IdeaList.vue per EventBus an IdeaSearchView uebertagen
        };
    },
    computed: {
        ...mapState({
            storeFilter: state => state.ideasList.filter 
        })
    },
    methods: {
        ...mapMutations({
            updateStoreFilter: 'setFeatureState'
        }),
        updateFilter() {
            this.updateStoreFilter({
                feature: 'ideasList',
                key: 'filter',
                value: { ...this.localFilter }
            });
        },
        createDefaultFilter(field, headerField) {
            let value = this.$store.state.ideasList.selectedRow[this.$store.state.ideasList.headerMap[headerField]];
            let filter;

            // Zahlenfelder
            if (field === 'id' || field === 'prio') {  // Felder, die Zahlen sind
                filter = `=${value}`;
            }
            // Lange Text-Felder
            else if (value.length > 20) {
                    value = value.substring(0, 20);
                    filter = `like '%${value}%'`;
            }
            // Normale Text-Felder
            else {
                filter = `='${value}'`;
            }

            this.$refs[`${field}Input`].value = filter;
            this.localFilter[field] = filter;
            this.updateFilter();
        },
        resetFilter() {
            this.localFilter = {}
            this.updateFilter();
        },
        handleQueryError(msg = null) {
            this.error = msg;
        },
    },
    mounted() {
        if (this.storeFilter && Object.keys(this.storeFilter).length > 0) {
            this.localFilter = { ...this.storeFilter };
        }
    },
    created() {
        EventBus.on('queryError', this.handleQueryError);
    },
    beforeUnmount() {
        EventBus.off('queryError', this.handleQueryError);
    },  
};
</script>

