<template>
    <div class="group-item">
        <label>Beyond Edges</label>
    </div>
</template>

<script>
export default {
    name: 'FreestyleQueryMenu',
    data() {
        return {
            requestParams: {
            },
        };
    },
};
</script>

