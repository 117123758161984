<template>
    <div class="ideas-list">
        <div class="app-content">
            <div class="master-view" ref="masterArea" tabindex="0" @keydown="handleTableKeydown" v-show="!isDetailViewMaximized">
                <table v-if="data" ref="masterTable">
                    <thead>
                        <tr>
                            <th v-for="(headerItem, index) in header"
                                :key="headerItem" 
                                :style="columnStyles[index]"
                                @click="sortColumn(index, 0)">
                                {{ headerItem }}
                                <span v-if="currentSortColumn === index">
                                    {{ currentSortOrder === 'asc' ? '↑' : '↓' }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(rowData, rowIndex) in data"
                            :key="`rowData.${rowIndex}`"
                            v-show="showCompletedItems || rowData[headerMap['Status']].toLowerCase() !== 'done'"
                            :class="{ 'selected': selectedRow 
                                                  && rowData
                                                  && selectedRow[$store.state.ideasList.headerMap['ID']] === rowData[$store.state.ideasList.headerMap['ID']]
                                    }"
                            @click="selectRow(rowData, 0)">
                            <td v-for="(cell, index) in rowData"
                                :key="`cell-${index}-${rowIndex}`"
                                :style="cellStyles[rowIndex][index]">
                                {{ types[index] === 'text' ? truncateText(cell, 30) : cell }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- master-view -->

            <div class="resize-handle" @mousedown="startResizing" v-show="!isDetailViewMaximized"></div>

            <div class="detail-view" v-if="selectedRow || isNewIdea">
                <div class="control-row">
                        <label class="labeled-checkbox" v-for="(value, key) in componentNames" :key="`control-row-${key}`">
                            <input type="checkbox" v-model="selectedComponents" :value="key" :name="key" :id="`checkbox-${key}`">
                            {{ value }}
                        </label>

                    <div class="action-icon-group">
                        <previous-icon class="action-icon" @click="previousItem"></previous-icon>
                        <next-icon class="action-icon" @click="nextItem"></next-icon>
                        <minimize-icon class="action-icon" v-show="isDetailViewMaximized" @click="toggleMasterView"></minimize-icon>
                        <maximize-icon class="action-icon" v-show="!isDetailViewMaximized" @click="toggleMasterView"></maximize-icon>
                    </div>
                </div> <!-- "control-row" -->
                <component v-for="comp in selectedComponents" :is="comp" :key="comp"></component>
            </div> <!-- "detail-view" -->
        </div> <!-- app-content -->
    </div> <!--  ideas-list -->
</template>

<script>
import IdeasListView from '@/components/ideas/IdeasListView'
import IdeasSearchView from '@/components/ideas/IdeasSearchView'
import { mapState } from 'vuex';
import { EventBus } from '@/eventBus.js';

export default {
    name: 'IdeasList',
    components: {
        IdeasListView,
        IdeasSearchView,
    },
    computed: {
        ...mapState({
            filter: state => state.ideasList.filter
        }),
        selectedRow() {
            return this.$store.state.ideasList.selectedRow;
        },
        header() {
            return this.$store.state.ideasList.header;
        },
        headerMap() {
            return this.$store.state.ideasList.headerMap;
        },
        columnStyles() {
            // Ausblenden der ID-Spalte in der Kopfzeile
            const idColumnIndex = this.headerMap['ID'];
            return Object.keys(this.headerMap).map(columnName => ({
                display: this.headerMap[columnName] === idColumnIndex ? 'none' : ''
            }));
        },
        cellStyles() {
            const idColumnIndex = this.headerMap['ID'];
            const statusColumnIndex = this.headerMap['Status'];
            const prioColumnIndex = this.headerMap['Prio'];

            return this.data.map(row =>
                Object.keys(this.headerMap).map(columnName => {
                    const index = this.headerMap[columnName];
                    if (index === idColumnIndex) {
                        return { display: 'none' }; // ID-Spalte ausblenden
                    }
                    else if (index === statusColumnIndex) {
                        return this.getStatusStyle(row[index]);
                    }
                    else if (index === prioColumnIndex) {
                        return this.getPrioStyle(row[index]);
                    }
                    return {}; // Standardstil
                })
            );
        },
    },
    data() {
        return {
            data: [],
            currentSortColumn: null,
            currentSortOrder: 'asc',
            isDetailViewMaximized: false,
            showCompletedItems: false,
            selectedComponents: ['IdeasListView', 'IdeasSearchView'], 
            componentNames: {
                IdeasSearchView: "Search",
            },
            error: null,
            isNewIdea : null,
        };
    },
    methods: {
        async fetchData(selectedId) { // parameter ist nur fuer die selektion aus der Liste, nicht zum Uebertragen an den Server
            let requestPayload = [
                'q',
                "ideas",
                this.cleanFilter(),
                ["id ID", "project Project", "subject Subject", "title Title", "description Description", "status Status", "prio Prio"]
            ];
            try {
                const response = await fetch('/qe/db', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestPayload),
                });

                if(!response.ok) {
                    throw new Error('Netzanfrage fehlgeschlagen');
                }

                const data = await response.json();

                if(data.error) {
                    throw new Error(`${data.error}`);
                }
                this.types = data.types;
                if (Array.isArray(data.rows)) {
                    this.data = data.rows;
                    this.$store.commit('setFeatureState', {
                        feature: 'ideasList',
                        key: 'header',
                        value: data.header
                    });
                }
                else {
                    console.error(`Unerwartetes Antwort-Format: ${this.error}`);
                    throw new Error('InnerData ist kein Array'); // wird aktuell in IdeasSearchView angezeigt
                }

                // Ermitteln der Spaltenindizes
                if( selectedId > 0 ) {
                    const newRow = this.data.find(row => row[this.headerMap['ID']] === selectedId);
                    if (newRow) {
                        this.selectRow(newRow, false);
                    }
                }
                if( !this.currentSortColumn ) {
                    this.currentSortColumn = this.headerMap['Prio'];
                }
                if(this.currentSortColumn) {
                    this.sortColumn(this.currentSortColumn, 1);
                } 
                EventBus.emit('queryError', null);
            }
            catch(error) {
                const msg = `Idea-Query fehlgeschlagen: ${error.message}`;
                console.log(msg);
                EventBus.emit('queryError', msg);
            }
        },
        cleanFilter() {
            const filter = {};
            for (const key in this.filter) {
                if (this.filter[key] != null && this.filter[key] !== "") { // nur wenn ein Wert vorhanden ist
                    filter[key] = this.filter[key];
                }
            }
            return filter;
        },
        getStatusStyle(status) {
            switch (status) {
                case 'active':
                    return { color: "#3d30fe", "text-align": "center" }; // Blau
                case 'idle':
                    return { color: "#222222", "text-align": "center" }; // Gelb
                case 'done':
                    return { color: "#229933", "text-align": "center" }; // Grün
                default:
                    return {};
            }
        },
        getPrioStyle(prio) {
            if( prio === null ||  prio === '')
            {
                return {};
            }
            let style = { "text-align":"right" }
            if( prio < 10) {
                 style["backgroundColor"] = "var(--input-background-color)";
            }
            return style;

        },
        sortColumn(columnIndex, reload) {
            if ( reload === 0 )
            {
                if (this.currentSortColumn === columnIndex) {
                    this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
                }
                else {
                    this.currentSortColumn = columnIndex;
                    this.currentSortOrder = 'asc';
                }
            }

            this.data.sort((a, b) => {
                let aValue = a[columnIndex];
                let bValue = b[columnIndex];

                // Lambda zum Prüfen, ob der Wert null oder ein leerer String ist
                const isNullOrEmpty = (value) => value == null || value === '';

                // Fall 1: a ist null/leer und b nicht
                if (isNullOrEmpty(aValue) && !isNullOrEmpty(bValue)) return 1;

                // Fall 2: a ist nicht null/leer und b ist null/leer
                if (!isNullOrEmpty(aValue) && isNullOrEmpty(bValue)) return -1;

                // Fall 3: Beide sind null oder leer
                if (isNullOrEmpty(aValue) && isNullOrEmpty(bValue)) return 0;

                // Fall 4: Keiner der Werte ist null oder leer
                if (this.types[columnIndex] === 'float' || this.types[columnIndex] == "int") {
                    return (this.currentSortOrder === 'asc' ? 1 : -1) * (parseFloat(aValue) - parseFloat(bValue));
                }
                else if (this.types[columnIndex] === 'datetime') {
                    return (this.currentSortOrder === 'asc' ? 1 : -1) * (new Date(aValue) - new Date(bValue));
                }
                else {
                    return (this.currentSortOrder === 'asc' ? 1 : -1) * aValue.toString().localeCompare(bValue.toString());
                }
            });
        },
        truncateText(text, maxLength) {
            if (text && text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
            return text;
        },
        startResizing(event) {
            event.preventDefault();
            let initialX = event.clientX;
            const masterArea = this.$refs.masterArea;
            let initialWidth = masterArea.offsetWidth;

            const doResize = (x) => {
                let deltaX = x - initialX;
                let newWidth = Math.max(initialWidth + deltaX, 100); // Mindestbreite der Tabelle
                masterArea.style.width = `${newWidth}px`;
            };

            const onMouseMove = (e) => {
                window.requestAnimationFrame(() => doResize(e.clientX));
            };

            const onMouseUp = () => {
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
            };

            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
        },
        nextItem() {
            this.moveSelection(1); // Bewegt die Auswahl eine Zeile nach unten
        },

        previousItem() {
            this.moveSelection(-1); // Bewegt die Auswahl eine Zeile nach oben
        },
        handleTableKeydown(e) {
            switch(e.key) {
                case 'ArrowUp':
                    e.preventDefault();
                    this.moveSelection(-1);
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    this.moveSelection(1);
                    break;
                case 'PageUp':
                    e.preventDefault();
                    this.moveSelection(-10); // Annahme: 10 Zeilen pro Seite
                    break;
                case 'PageDown':
                    e.preventDefault();
                    this.moveSelection(10); // Annahme: 10 Zeilen pro Seite
                    break;
            }
        },
        moveSelection(steps) {
          if (!this.data || !Array.isArray(this.data)) {
            // Data ist entweder null oder nicht im Array-Format, daher kann nicht darauf zugegriffen werden.
            return;
          }

          const currentIndex = this.data.indexOf(this.$store.state.ideasList.selectedRow);
          const newIndex = currentIndex + steps;
          if (newIndex >= 0 && newIndex < this.data.length) {
            const newRowData = this.data[newIndex];
            this.selectRow(newRowData, 0);
            this.ensureVisible();
          }
        },
        ensureVisible() {
            this.$nextTick(() => {
                const selectedRow = this.$refs.masterTable.querySelector('.selected');
                const headerHeight = this.$refs.masterTable.querySelector('thead').offsetHeight;

                if (selectedRow && this.$refs.masterArea) {
                    const container = this.$refs.masterArea;
                    const containerRect = container.getBoundingClientRect();
                    const rowRect = selectedRow.getBoundingClientRect();

                    // Prüfe, ob die Zeile oberhalb des sichtbaren Bereichs des Containers liegt
                    if (rowRect.top < containerRect.top + headerHeight) {
                        // Scrollt nach oben, unter Berücksichtigung der Höhe des Headers
                        container.scrollTop -= (containerRect.top + headerHeight) - rowRect.top;
                    } else if (rowRect.bottom > containerRect.bottom) {
                        // Scrollt nach unten, sodass die Zeile vollständig sichtbar ist
                        container.scrollTop += rowRect.bottom - containerRect.bottom;
                    }
                }
            });
        },
        toggleMasterView() {
            this.isDetailViewMaximized = !this.isDetailViewMaximized;
        },
        selectRow(rowData, isNewIdea) {
            this.isNewIdea = isNewIdea;
            this.$store.commit('setFeatureState', {
                feature: 'ideasList',
                key: 'selectedRow',
                value: rowData
            });
        },
        handleNewIdeaCanceled() {
            this.isNewIdea = 0;
            this.$store.commit('setFeatureState', {
                    feature: 'ideasList',
                    key: 'selectedRow',
                    value: null
                });
        },
        handleToggleShowCompleted(show) {
            this.showCompletedItems = show;
        },
        handleIdeaUpdated(options) {
            this.fetchData(options.id);
        },
    },
    watch: {
        filter: {
            deep: true,
            handler() {
                this.fetchData();
            }
        }
    },
    mounted() {
        EventBus.on('ideaUpdated', this.handleIdeaUpdated);
        EventBus.on('createNewIdea', this.selectRow.bind(this, null, 1));
        EventBus.on('newIdeaCanceled', this.handleNewIdeaCanceled);
        EventBus.on('toggleShowCompleted', this.handleToggleShowCompleted);


        this.fetchData();
    },
    beforeUnmount() {
        EventBus.off('ideaUpdated', this.handleIdeaUpdated);
        EventBus.off('createNewIdea', this.selectRow.bind(this, null, 1));
        EventBus.off('newIdeaCanceled', this.handleNewIdeaCanceled);
        EventBus.off('toggleShowCompleted', this.handleToggleShowCompleted);
    },
};
</script>
