export function arrayToMap(array) {
    return array.reduce((map, item, index) => {
        map[item] = index;
        return map;
    }, {});
}

// wird z.B. in FreestyleQuery.vue verwendet
// Die Hoehe der Textarea passt sich dem Text an,
// Bis zu maxHeigt wird sie wachsen, danach wird ein Scrollbalken angezeigt
export function adjustHeight(target, maxHeight) {
    const style = window.getComputedStyle(target);
    const padding = parseInt(style.paddingTop) + parseInt(style.paddingBottom);
    const border = parseInt(style.borderTopWidth) + parseInt(style.borderBottomWidth);

    target.style.height = "0px";
    let contentHeight = target.scrollHeight - padding - border;

    if (contentHeight > maxHeight) {
        target.style.overflowY = 'scroll';
        target.style.height = `${maxHeight}px`;
    } else {
        target.style.overflowY = 'hidden';
        target.style.height = `${contentHeight}px`;
    }
}
