<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" @click="onClick"> 
        <path d="M6 2H4.5a2 2 0 0 0-2 2v1.5"></path>
        <path d="M12 2h1.5a2 2 0 0 1 2 2v1.5"></path>
        <path d="M12 15.5h1.5a2 2 0 0 0 2-2V12"></path>
        <path d="M6 15.5H4.5a2 2 0 0 1-2-2V12"></path>
    </svg>
</template>
<script>
export  default {
    methods: {
        onClick() {
            this.$emit('minimize');
        }
    }
};
</script>
