<template>
    <div id="instruction-input-container" class="text-input-container">
        <input id="instruction-title-input" placeholder="Title..." type="text" v-model="localInstruction.title" />
        <textarea 
            ref="instructionInput"
            class="text-input"
            v-tab-consume="{eventName: 'ctrl-enter-message-input', escapeEventName: 'escape-callback' }"
            v-model="localInstruction.content"
            @input="checkInstructionInputHeight"
            @escape-callback="cancelEdit"
            placeholder="Instructions..."
            rows="1"
            id="content-textarea"></textarea>
        <submit-icon class="submit-icon" @click="submitInstruction"></submit-icon>
    </div>
    <div v-if="error" class="error-message">{{ error }}</div>
</template>

<script>
import './styles.css';
import { adjustHeight } from '@/utils/helpers.js';

export default {
    name: 'InstructionsEdit',
    props: {
        instruction: {
            type: Object,
            default: () => ({ id: null, title: '', content: '' })
        }
    },
    computed: {
        isChanged() {
            return JSON.stringify(this.localInstruction) !== JSON.stringify(this.instruction);
        },
    },
    data() {
        return {
            maxInstructionInputHeight:600, 
            localInstruction: { ...this.instruction },
            error: null
        };
    },
    methods: {
        submitInstruction() {
            if (!this.localInstruction.title || !this.localInstruction.content) {
                this.error = 'Alle Felder sind auszufüllen.';
                return;
            }
            this.error = null;
            this.$emit('submit', this.localInstruction);
        },
        cancelEdit() {
            this.$emit('cancel');
        },
        checkInstructionInputHeight(event) {
            if( event )
            {
                adjustHeight(event.target, this.maxInstructionInputHeight);
            }
        },
    },
    mounted() {
        adjustHeight(this.$refs.instructionInput, this.maxInstructionInputHeight);

        // Event-Listener für Escape-Taste
        this.escapeKeyListener = (e) => {
            if (e.key === 'Escape') {
                this.cancelEdit();
            }
        };
        document.addEventListener('keydown', this.escapeKeyListener);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.escapeKeyListener);
    }
};
</script>

