<template>
    <div class="component-view">
        <hr>
        <div>Parameter: {{ $store.state.requestParams }}</div>
        <hr>
        <div>Header: {{ header }}</div>
        <hr>
        <div>Daten: {{ rowData }}</div>
        <hr>
    </div>
</template>

<script>
export default {
    props: ['header'],
    computed: {
        rowData() {
            return this.$store.state.selectedRow;
        }
    }
};
</script>

