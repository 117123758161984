<template>
    <div id="freestyle-panel">

        <div v-if="error" class="error-message">{{ error }}</div>

        <!-- control-row erste Zeile -->
        <div class="control-row">
            <div class="group-left">
                <div class="group-item">
                    <label for="model-select">Model:</label>
                    <select id="model-select" v-model="fs.model">
                        <option v-for="option in modelOptions" :key="option.key" :value="option.key">
                            {{ option.value }}
                        </option>
                    </select>
                </div>

                <div class="group-item">
                    <label for="instruction-select">Instructions:</label>
                    <select id="instruction-select" v-model="instructionId">
                        <option v-for="instruction in instructions" :key="instruction.id" :value="instruction.id">
                            {{ instruction.title }}
                        </option>
                    </select>
                    <edit-icon class="icon-button" @edit="showInstructionEdit({edit:true})" />
                    <addnew-icon class="icon-button" @add="showInstructionEdit({edit:false})" />
                    <minus-icon class="icon-button" @delete="deleteInstruction" />
                 </div>
            </div>
            <div class="group-right">
                <div class="group-item">
                    <button id="clear-conversation" @click="clearConversation">Clear Conversation</button>
                </div>
            </div>
        </div>

        <!-- Message-History -->
        <div id="message-history-container" ref="chatHistory">
            <div class="chat-message"
                    v-for="message in messageHistory"
                    :key="message.id"
                    :class="{'my-message': message.own, 'ai-message': !message.own}">
                <span class="message-sender" v-if="message.own"> Sie </span>
                <span class="message-sender" v-else> AI </span>
                <span class="message-content" v-html="message.text"></span>
            </div>
        </div> <!-- message-history-container -->

        <!-- Message-Input -->
        <div id="message-input-wrapper" class="text-input-container"> 
            <textarea ref="messageInput"
                class="text-input"
                v-tab-consume="{eventName: 'ctrl-enter-message-input'}"
                v-model="inputMessage"
                @input="checkMessageInputHeight"
                placeholder="Welcher Tag kommt nach Freitag?"
                rows="1"
                id="message-input"></textarea>
            <submit-icon class="submit-icon" @click="sendGptMessage"></submit-icon>
        </div> <!-- Message-Input -->

        <InstructionEditView v-if="editInstruction" :instruction="editInstruction" @submit="handleSubmitInstruction" @cancel="handleCancelInstructionEdit"/>
    </div> <!-- freestyle-panel -->
</template>

<script>
import './styles.css';
import { EventBus } from '@/eventBus.js';
import InstructionEditView from '@/components/freestyle/InstructionEditView';
import { adjustHeight } from '@/utils/helpers.js'; 

export default {
    name: 'FreestyleQuery',
    components: {
        InstructionEditView
    },
    computed: {
        modelOptions() {
            return this.$store.getters.getSetting('qe.fs.model');
        },
    },
    data() {
        return {
            maxMessageInputHeight: 600,
            messageEventName: 'ctrl-enter-message-input', 

            messageHistory: [],
            inputMessage: 'wie spät ist es bitte?',

            instructions: [],
            instructionId: null,

            editInstruction: null,
            fs: {
                model:'gpt-4' // default model 
            },
            error: null,
        };
    },
    methods: {
        async submitInstruction(mode, instruction) {

            this.error = null;
            let requestBody = [mode, "instructions"];

            if (mode !== 'i') { // bei u und d wird die id uebergeben
                requestBody.push(instruction.id);
            }
            if (mode !== 'd') { // bei i und u werden zusaetzlich die Spalten mit den Daten uebergeben 
                requestBody.push({ title: instruction.title, content: instruction.content});
            } 

            fetch('/qe/db', { 
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(requestBody),
            })
            .then(response => response.json())
            .then(data => {
                if(!data.success) {
                    throw new Error('DB: ' +  data.error ? data.error : 'kein Hinweis auf Ursache');
                }
                this.fetchInstructions();
            })
            .catch(data => {
                this.error = `${data}`;
            });
        },
        async fetchInstructions() {
            try {
                const response = await fetch('/qe/db/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(["q", "instructions", {}, ["id", "title", "content"]])
                });
                if (!response.ok) {
                    throw new Error('Fehler beim Laden der Instructions');
                }
                const data = await response.json();

                this.instructions = data.rows.map(item => ({
                    id: item[0],
                    title: item[1],
                    content: item[2]
                }));

                if (!this.instructions.some(instruction => instruction.id === this.instructionId)) {
                    this.instructionId = this.instructions.length > 0 ? this.instructions[0].id : null;
                }
            }
            catch (error) {
                console.error('Fehler beim Abrufen der Instructions:', error);
            }
        }, // fetchInstructions
        async sendGptMessage() {
            // Pruefen ob es eine Eingabe gibt
            if (this.inputMessage.trim() === '') return;

            // Als User-Message in die Message-History schreiben
            this.appendMessage({ id: Date.now(), text: this.inputMessage, own: true });

            // Umwandlung der messageHistory in gpt-api-Format
            const messageHistory = this.messageHistory.map(msg => ({
                role: msg.own ? 'user' : 'assistant',
                content: msg.text
            }));

            // Ausgewaehlten SystemPrompt holen
            const instruction = this.instructions.find(i => i.id === this.instructionId);
            const content = instruction ? instruction.content : '';

            const systemPrompt = {
                role: 'system',
                content: content
            }

            // Reset Input
            this.resetMessageTextarea();

            try {
                const requestBody = {
                    messages: [systemPrompt, ...messageHistory],
                    model: this.fs.model,
                };

                const response = await fetch('/qe/fs', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody)
                });

                if (!response.ok) {
                    throw new Error('Netzanfrage fehlgeschlagen');
                }

                const data = await response.json();

                if (data && Array.isArray(data)) {
                    data.forEach(message => {
                        this.appendMessage({ id: Date.now(), text: message.content, own: false });
                    });
                    this.$nextTick(() => {
                        if(window.MathJax) {
                            window.MathJax.typesetPromise();
                        }
                    });
                }
            }
            catch (error) {
                console.error('Fehler beim Senden der Nachricht:', error);
                this.appendMessage({ id: Date.now(), text: 'Es gab einen Fehler bei der Kommunikation mit der AI.', own: false });
            }
        }, // sendGptMessage
        appendMessage(newMessage) {
            const container = this.$refs.chatHistory;
            const isScrolledToBottom = container.scrollHeight - container.clientHeight <= container.scrollTop + 1;
            this.messageHistory.push(newMessage);
            this.$nextTick(() => {
                if (isScrolledToBottom) {
                    container.scrollTop = container.scrollHeight;
                }
            });
        },
        resetMessageTextarea() {
            this.inputMessage = '';
            this.$refs.messageInput.style.height = "auto";
        },
        checkMessageInputHeight(event) {
            if( event )
            {
                adjustHeight(event.target, this.maxMessageInputHeight);
            }
        },
        clearConversation() {
            this.messageHistory = [];
            this.resetMessageTextarea();
        },
        showInstructionEdit(modus) {
            // Damit ein eventuell vorhandener Edit-Dialog reagiert, muss man wegen der vue-Reaktivitaet
            // dieses Objekt erst einmal zuruecksetzen. Ich kann aktuell nicht sagen, dass ich das vollstaendig verstanden habe.
            this.editInstruction = null;
            this.$nextTick(() => {
                this.editInstruction = modus.edit
                    ?  this.instructions.find(i => i.id === this.instructionId) // Edit
                    :  { id: null, title: '', content: '' }; // Add
            });
        },
        hideInstructionEdit() {
            this.editInstruction = null;
        },

        deleteInstruction() {
            const instruction = this.instructions.find(i => i.id === this.instructionId) // Edit
            if( instruction )
            {
                EventBus.emit('show-confirmation-dialog', {
                    title: 'Instruction Löschen',
                    message: `Soll die Instruction <strong>${instruction.title}</strong> wirklich gelöscht werden?`,
                    callback: confirmed => {
                        if (confirmed) {
                            this.submitInstruction('d', instruction);
                        }
                    }
                });
            }
        },

        handleCancelInstructionEdit() {
            // nichts zu tun, nur Komponente wieder abschalten
            this.editInstruction = null;
        },
        handleSubmitInstruction(instruction) {
            if ( instruction )
            {
                this.submitInstruction(instruction.id != null? 'u':'i', instruction);
            }
            this.editInstruction = null;
        },
        renderMath() {
            this.$nextTick(() => {
                if (window.MathJax) {
                    let historyElement = this.$refs.chatHistory;
                    if (historyElement) {
                        window.MathJax.typesetPromise([historyElement]);
                    }
                }
            });
        },
    },
    watch: {
        messageHistory() {
            this.renderMath();
        },
        instructionId() {
            // Wenn sich im Instructions-Select  die Selektion ändert,
            // soll ein vorhandener Edit-Dialog beendet werden.
            if (this.editInstruction !== null) {
                this.handleCancelInstructionEdit();
            }
        },
    },
    mounted() {
        this.fetchInstructions();
        this.$refs.messageInput.addEventListener(this.messageEventName, this.sendGptMessage);
    },
    beforeUnmount() {
        this.$refs.messageInput.removeEventListener(this.messageEventName, this.sendGptMessage);
    },
};
</script>

