<template>
    <div class="component-view">
        <div class="info-block" v-if="rowData">
            <div>
                Die Aktie {{ getRowData('Symbol') }} bewegte sich
                in {{ getParameterValue('w') }} Handelstagen
                von {{ getRowData('Start') }} bis {{ getParameterValue('s') }}
                um  mehr als {{ getParameterValue('p') }}%.
            </div>
            <div>
                Der {{ getRowData('Perf') >= 0 ? 'Gewinn' : 'Verlust' }}
                am Stichtag  waren {{ formatValue(getRowData('Perf')) }}.
            </div>
            <div>
                Übersicht der Entwicklung in den verschiedenen Perioden nach dem Stichtag {{ getParameterValue('s') }}.
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Periode nach Stichtag</th>
                        <th>Maximaler Verlust</th>
                        <th>Maximaler Profit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="daysAfter in extractDaysAfter()" :key="daysAfter">
                        <td>{{ daysAfter }} Tage</td>
                        <td class="loss">
                            {{ formatValue(getRowData(`MaxLoss-${daysAfter}`)) }}
                        </td>
                        <td class="profit">
                            {{ formatValue(getRowData(`MaxProf-${daysAfter}`)) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: ['header'], // das ist der Header aus InfoView
    computed: {
        rowData() {
            return this.$store.state.maxMovers.selectedRow;
        },
        headerMap() {
            return this.$store.state.maxMovers.headerMap;
        }
    },
    methods: {
        getParameterValue(field) {
            return this.$store.state.maxMovers.requestParams[field];
        },
        getRowData(field) {
            const index = this.headerMap[field];
            return index !== undefined ? this.rowData[index] : null;
        },
        formatValue(value) {
            return value ? `${value.toFixed(2)}%` : 'N/A';
        },
        extractDaysAfter() {
            if (!this.headerMap) {
                return [];
            }
            return Object.keys(this.headerMap)
                .filter(h => h.startsWith('MaxLoss-') || h.startsWith('MaxProf-'))
                .map(h => h.split('-')[1])
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => a - b); // Sortiere die Tage
        },
    }
};
</script>
<style>
.info-block {
    background-color: #f9f9f9; /* dezent grauer Hintergrund */
    padding: 1rem;
    border: 1px solid #2c3e50;
    max-width: 730px; /* Breite des Infoblocks */
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.3); /* Schatten */
    border-radius: 2px; /* Abgerundete Ecken */
}
.info-block div { /* im Info-Block sind die <div> wie <p> um Zeilen zu schaffen  */ 
    margin-bottom: 0.3rem; /* Abstand zwischen Zeilen-Divs */
}
.info-block table {
    margin-top: 2rem;
    max-width: 70%;
}
.info-block th {
    text-align: left;
}
.info-block td.profit {
    color: #4C9F30;
    text-align: right;
}
.info-block td.loss {
    color: #e44336;
    text-align: right;
}
</style>
