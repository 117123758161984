<template>
    <div class="confirmation-dialog-overlay" v-show="visible" ref="overlay">
        <div class="confirmation-dialog" @mousedown="initDrag">
            <div class="confirmation-dialog-header">
                <h3>{{ title }}</h3>
            </div>
            <div class="confirmation-dialog-body" v-html="message"/>
            <div class="confirmation-dialog-footer">
                <button class="confirmation-dialog-button cancel" @click="cancel">Abbrechen</button>
                <button class="confirmation-dialog-button confirm" @click="confirm">Ok</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        message: String,
        visible: Boolean,
    },
    methods: {
        confirm() {
            this.$emit('confirm');
        },
        cancel() {
            this.$emit('cancel');
        },
        initDrag(event) {
            const dialog = this.$refs.overlay.firstChild;
            let offsetX = event.clientX - dialog.getBoundingClientRect().left;
            let offsetY = event.clientY - dialog.getBoundingClientRect().top;

            const move = (moveEvent) => {
                dialog.style.position = 'absolute';
                dialog.style.left = `${moveEvent.clientX - offsetX}px`;
                dialog.style.top = `${moveEvent.clientY - offsetY}px`;
            };

            document.addEventListener('mousemove', move);

            // Stoppe das Bewegen beim Loslassen der Maustaste
            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', move);
            }, { once: true });
        },
    },
};
</script>

<style scoped>
.confirmation-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}

.confirmation-dialog {
    width: 100%;
    max-width: 450px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
}

.confirmation-dialog-header {
    padding: 15px 20px;
    background: #f4f4f5;
    border-bottom: 1px solid #ebedf0;
    background: #f8f9fa; /* Helleres Grau für den Header */
}

.confirmation-dialog-header h3 {
    font-weight: 600; /* Etwas fetter, um Bedeutung zu signalisieren */
}

.confirmation-dialog-body {
    padding: 20px;
    color: #666;
    font-size: 1rem; /* Standard Schriftgröße für Konsistenz */
}

.confirmation-dialog-footer {
    padding: 10px 20px;
    text-align: right;
    background: #f4f4f5;
    border-top: 1px solid #ebedf0;
}

.confirmation-dialog-button {
    padding: 10px 20px;
    margin-left: 10px;
    border: 1px solid #dcdfe6; /* Grenze, um Tiefe zu schaffen */
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s; /* Fügt eine sanfte Übergangsanimation hinzu */
    }

.confirmation-dialog-button.cancel {
    background-color: #f8f9fa;
    color: #606266; /* Ein dunkleres Grau für neutrale Aktionen */
}

.confirmation-dialog-button.confirm {
    background-color: #dc3545; /* Warnfarbe für irreversible Aktionen */
    color: white;
    border-color: #dc3545; /* Grenze in derselben Farbe wie der Button */
}

.confirmation-dialog-button:hover {
    filter: brightness(0.9); /* Abdunkeln im Hover */
}
</style>

