<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" @click="onClick">
        <path d="M6 14l5-5-5-5"></path>
    </svg>
</template>
<script>
export default {
    methods: {
        onClick() {
            this.$emit('next');
        }
    }
};
</script>

