<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" @click="onClick"> 
        <path d="M2,12 L22,12 M22,12 L16,6 M22,12 L16,18"/>
    </svg>
</template>

<script>
export default {
    methods: {
        onClick() {
            this.$emit('submit');
        }
    }
};
</script>
