<template>
    <div class="group-item">
        <label class="label">Stichtag:</label>
        <input class="input-field" 
                v-select-all-on-focus
                :value="$store.state.maxMovers.requestParams.s"
                @input="updateRequestParams('s', $event.target.value, 'date')"
                type="date" name="stichtag" id="stichtag-input">
    </div> <!-- class="group-item" -->

    <div class="group-item">
        <label class="label">Tage:</label>
        <input class="input-field"
                v-select-all-on-focus
                :value="$store.state.maxMovers.requestParams.w"
                @input="updateRequestParams('w', $event.target.value, 'number')"
                type="number"
                style="width: 50px;"
                name="tage" id="tage-input">
    </div> <!-- class="group-item" -->

    <div class="group-item">
        <label class="label">Bewegung:</label>
        <input class="input-field"
            v-select-all-on-focus
                :value="$store.state.maxMovers.requestParams.p"
                @input="updateRequestParams('p', $event.target.value, 'number')"
                type="number"
                style="width: 50px;"
                name="bewegung" id="bewegung-input">
    </div> <!-- class="group-item" -->

    <div class="group-item">
        <label class="label">Backtest Tage:</label>
        <div v-for="(day, index) in $store.state.maxMovers.requestParams.b" :key="index" style="display: inline-block;">
            <input class="input-field"
                v-select-all-on-focus
                :value="day"
                @input="updateDay(index, $event.target.value)"
                type="number" min="0"
                style="width: 40px;"
                name="backtest-tag"
                :id="`backtest-tag-${index}`"
            >
            <button class="small-button" tabindex="-1" @click="removeDay(index)">-</button>
        </div>
        <button class="small-button" tabindex="-1" @click="addDay">+</button>
    </div> <!-- class="group-item" -->

    
    <div class="group-item">
        <button class="sendbtn small-button" @click="sendQuery('maxMovers')">Send Query</button>
    </div>
</template>

<script>
export default {
    name: 'MaxMoversMenu',
    methods: {
        updateRequestParams(key, value, typ) {
            this.$store.commit('updateRequestParams', { feature: 'maxMovers', key, value, type: typ });
        },
        updateDay(index, value) {
            this.$store.commit('updateMaxMoversDay', { index, value });
        },
        addDay() {
            this.$store.commit('addMaxMoversDay');
        },
        removeDay(index) {
            this.$store.commit('removeMaxMoversDay', index);
        },
        sendQuery(feature) {
            this.$store.dispatch('sendQuery', feature);
        }
    },
};
</script>
<style scoped>
    .input-field {
        text-align: right;
    }
    input[type="number"]::-webkit-inner-spin-button {
        margin-left: 8px;
        margin-right: -2px;
        height:18px;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        padding-right:15px;
        margin-right: -10px;
        margin-left: 4px;
    }
</style>
