<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" @click="onClick">
        <line x1="3" y1="15" x2="15" y2="15"></line>
    </svg>
</template>
<script>
export default {
    methods: {
        onClick() {
            this.$emit('minimize');
        }
    }
};
</script>
