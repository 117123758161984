<template>
    <div class="max-movers">
        <div class="app-content">
            <div class="master-view" ref="masterArea" tabindex="0" @keydown="handleTableKeydown" v-show="!isDetailViewMaximized">
                <table v-if="data" ref="masterTable">
                    <thead>
                        <tr>
                            <th v-for="(headerItem, index) in header" :key="headerItem" @click="sortColumn(index)">
                                {{ headerItem }}
                                <span v-if="currentSortColumn === index">
                                    {{ currentSortOrder === 'asc' ? '↑' : '↓' }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(rowData, rowIndex) in data"
                            :key="`row-${rowIndex}`"
                            :class="{ 'selected': selectedRow 
                                      && rowData
                                      && selectedRow[$store.state.maxMovers.headerMap['Symbol']] === rowData[$store.state.maxMovers.headerMap['Symbol']]
                                    }"
                            @click="selectRow(rowData)">
                            <td v-for="(cell, index) in rowData"
                                :key="`cell-${index}-${rowIndex}`"
                                :class="[getTypeClass(rowData, types[index], index), {'number-cell': isNumberCell(types[index])}]">
                                {{ formatCell(cell, types[index]) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- "master-view" -->

            <div class="resize-handle" @mousedown="startResizing" v-show="!isDetailViewMaximized"></div>

            <div class="detail-view" v-if="selectedRow">
                <div class="control-row">
                    <div class="group-item">
                        <label class="labeled-checkbox" v-for="(value, key) in componentNames" :key="`control-row-${key}`">
                            <input type="checkbox" v-model="selectedComponents" :value="key" :name="key" :id="`checkbox-${key}`">
                            {{ value }}
                        </label>
                    </div>

                    <div class="action-icon-group">
                        <previous-icon class="action-icon" @click="previousItem"></previous-icon>
                        <next-icon class="action-icon" @click="nextItem"></next-icon>
                        <minimize-icon class="action-icon" v-show="isDetailViewMaximized" @click="toggleMasterView"></minimize-icon>
                        <maximize-icon class="action-icon" v-show="!isDetailViewMaximized" @click="toggleMasterView"></maximize-icon>
                    </div>
                </div> <!-- "control-row" -->
                <component v-for="comp in selectedComponents" :is="comp" :key="comp"></component>
            </div> <!-- "detail-view" -->
        </div> <!-- "app-content" -->
    </div> <!-- "max-movers" -->
</template>

<script>
import { EventBus } from '@/eventBus.js';

import InfoView from '@/components/maxmovers/InfoView';
import ChartView from '@/components/maxmovers/ChartView';
import MontecarloView from '@/components/maxmovers/MontecarloView';
import DemoForNothingView from '@/components/maxmovers/DemoForNothingView';

export default {
    name: 'MaxMovers',
    components: {
        InfoView,
        ChartView,
        MontecarloView,
        DemoForNothingView,
    },
    computed: {
        selectedRow() {
            return this.$store.state.maxMovers.selectedRow;
        },
        header() {
            return this.$store.state.maxMovers.header;
        }
    },
    data() {
        return {
            data: [],
            types: [],
            // TabellenDaten
            currentSortColumn: null,
            currentSortOrder: 'asc',
            currentSymbol: null,
            error: null, 
            // Detail-Komponenten
            selectedComponents: ['InfoView', 'ChartView'],
            componentNames: {
                InfoView: "Info",
                ChartView: "Chart",
                MontecarloView: "Monte Carlo Simu",
                DemoForNothingView: "Demo for Nothing",
            },
            isDetailViewMaximized: false,
        };
    },
    methods: {
        async fetchData() {
            // Finde den Index der Symbol-Spalte
            const symbolIndex = this.header.indexOf('Symbol');
            const selectedRow = this.selectedRow;
            const selectedSymbol = selectedRow && symbolIndex !== -1 ? selectedRow[symbolIndex] : null;

            try {
                const response = await fetch('/qe/maxmovers', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.$store.state.maxMovers.requestParams),
                });

                if(!response.ok) {
                    throw new Error("Netzanfrage fehlgeschlagen");
                }
                const data = await response.json();

                this.types = data.types;
                this.$store.commit('setFeatureState', {
                    feature: 'maxMovers',
                    key: 'header',
                    value: data.header
                });
                this.data = data.data;

                // Finde den neuen Index für das ausgewählte Symbol

                if (Array.isArray(this.data)) {
                    const newIndex = selectedSymbol !== null ? this.data.findIndex(row => row[symbolIndex] === selectedSymbol) : -1;
                    if (newIndex !== -1) {
                        this.selectRow(this.data[newIndex]);
                    }
                    else {
                        // Die ausgewählte Zeile existiert nicht mehr.
                        this.$store.commit('setFeatureState', {
                            feature: 'maxMovers',
                            key: 'selectedRow',
                            value: null
                        });
                    }
                }
            }
            catch(error) {
                console.error('Fehler bei der Anfrage: ', error);
                this.error = error.toString();
            }
        },
        getTypeClass(rowData, type, columnIndex) {
            if (type !== 'float') {
                return;
            }

            if ([3, 4].includes(columnIndex)) {
                const openValue = parseFloat(rowData[3]);
                const lastValue = parseFloat(rowData[4]);
                if ((columnIndex === 3 && openValue < lastValue) || (columnIndex === 4 && openValue > lastValue)) {
                    return columnIndex === 3 ? 'green-cell' : 'red-cell';
                }
                return;
            }

            for (let i = 0; i < this.$store.state.maxMovers.requestParams.b.length; i++) {
                const baseIndex = 6 + 2 * i;
                if ([baseIndex, baseIndex + 1].includes(columnIndex)) {
                    const profitValue = Math.abs(parseFloat(rowData[baseIndex]));
                    const lossValue = Math.abs(parseFloat(rowData[baseIndex + 1]));
                    if (columnIndex === baseIndex && profitValue > lossValue) {
                        return 'green-cell';
                    } else if (columnIndex === baseIndex + 1 && profitValue < lossValue) {
                        return 'red-cell';
                    }
                    return;
                }
            }
        },
        formatCell(cell, type) {
            if (type === 'float') {
                return cell.toFixed(2);
            }
            return cell;
        },
        isNumberCell(type) {
            return type === 'float' /* oder andere numerische Typen prüfen */;
        },
        sortColumn(columnIndex) {
                if (this.currentSortColumn === columnIndex) {
                    this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
                } else {
                    this.currentSortColumn = columnIndex;
                    this.currentSortOrder = 'asc';
                }

                this.data.sort((a, b) => {
                    a = a[columnIndex];
                    b = b[columnIndex];
                    if (this.types[columnIndex] === 'float')
                    {
                        return (this.currentSortOrder === 'asc' ? 1 : -1) * (parseFloat(a) - parseFloat(b));
                    }
                    else if (this.types[columnIndex] === 'date')
                    {
                        return (this.currentSortOrder === 'asc' ? 1 : -1) * (new Date(a) - new Date(b));
                    }
                    else
                    {
                        return (this.currentSortOrder === 'asc' ? 1 : -1) * a.toString().localeCompare(b.toString());
                    }
                });
        },
        selectRow(rowData) {
            this.$store.commit('setFeatureState', {
                feature: 'maxMovers',
                key: 'selectedRow',
                value: rowData
            });
        },
        startResizing(event) {
            event.preventDefault();
            let initialX = event.clientX;
            const masterArea = this.$refs.masterArea;
            let initialWidth = masterArea.offsetWidth;

            const doResize = (x) => {
                let deltaX = x - initialX;
                let newWidth = Math.max(initialWidth + deltaX, 100); // Mindestbreite der Tabelle
                masterArea.style.width = `${newWidth}px`;
            };

            const onMouseMove = (e) => {
                window.requestAnimationFrame(() => doResize(e.clientX));
            };

            const onMouseUp = () => {
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
            };

            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
        },
        nextItem() {
            this.moveSelection(1); // Bewegt die Auswahl eine Zeile nach unten
        },

        previousItem() {
            this.moveSelection(-1); // Bewegt die Auswahl eine Zeile nach oben
        },
        handleTableKeydown(e) {
            switch(e.key) {
                case 'ArrowUp':
                    e.preventDefault();
                    this.moveSelection(-1);
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    this.moveSelection(1);
                    break;
                case 'PageUp':
                    e.preventDefault();
                    this.moveSelection(-10); // Annahme: 10 Zeilen pro Seite
                    break;
                case 'PageDown':
                    e.preventDefault();
                    this.moveSelection(10); // Annahme: 10 Zeilen pro Seite
                    break;
            }
        },
        moveSelection(delta) {
            if (!this.data || !Array.isArray(this.data) || !this.selectedRow) {
                return;
            }

            // Finde den Index der aktuellen ausgewählten Zeile
            const currentRowIndex = this.data.findIndex(
                        row => row[this.$store.state.maxMovers.headerMap['Symbol']] === this.selectedRow[this.$store.state.maxMovers.headerMap['Symbol']]
                        );

            // Berechne den neuen Index
            const newIndex = currentRowIndex + delta;
            if (newIndex >= 0 && newIndex < this.data.length) {
                this.selectRow(this.data[newIndex]);
                this.ensureVisible();
            }
        },
        ensureVisible() {
            this.$nextTick(() => {
                const selectedRow = this.$refs.masterTable.querySelector('.selected');
                const headerHeight = this.$refs.masterTable.querySelector('thead').offsetHeight;

                if (selectedRow && this.$refs.masterArea) {
                    const container = this.$refs.masterArea;
                    const containerRect = container.getBoundingClientRect();
                    const rowRect = selectedRow.getBoundingClientRect();

                    // Prüfe, ob die Zeile oberhalb des sichtbaren Bereichs des Containers liegt
                    if (rowRect.top < containerRect.top + headerHeight) {
                        // Scrollt nach oben, unter Berücksichtigung der Höhe des Headers
                        container.scrollTop -= (containerRect.top + headerHeight) - rowRect.top;
                    } else if (rowRect.bottom > containerRect.bottom) {
                        // Scrollt nach unten, sodass die Zeile vollständig sichtbar ist
                        container.scrollTop += rowRect.bottom - containerRect.bottom;
                    }
                }
            });
        },
        toggleMasterView() {
            this.isDetailViewMaximized = !this.isDetailViewMaximized;
        },
    },
    mounted() {
        EventBus.on('send-query', this.fetchData);
        EventBus.on('chart-next', this.nextItem);
        EventBus.on('chart-previous', this.previousItem);
        this.fetchData();
    },
    beforeUnmount() {
        EventBus.off('send-query', this.fetchData);
        EventBus.off('chart-next', this.nextItem);
        EventBus.off('chart-previous', this.previousItem);
    }
};
</script>
